import { Box, Typography, } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

const AppointmentTable = () => {
    const appointment = useSelector(state => state?.common?.appointment)
    const formattedStartTime = moment(appointment?.startTime).format('lll')
    const formattedEndTime = moment(appointment?.endTime).format('lll')

    return (
        <Box sx={{
            width: { xs: '300px', sm: '400px', md: "550px" },
            backgroundColor: '#f7f7f7',
            padding: '10px',
            borderRadius: '8px', textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }} >
            <Box ><Typography sx={{ fontSize: { xs: '15px', sm: '20px', md: '20px' } }}><strong style={{ color: 'black' }}>Meeting Details:</strong></Typography></Box>
            <table style={{ width: '100%', textAlign: 'left', marginTop: '10px', color: 'black' }}>
                <tbody>
                    <tr>
                        <td ><Typography sx={{ fontWeight: 'bold', fontSize: { xs: '15px', sm: '20px', md: '20px' } }}>Start Time</Typography></td>
                        <td><Typography sx={{ fontSize: { xs: '15px', sm: '20px', md: '20px' } }}>{formattedStartTime}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{ fontWeight: 'bold', fontSize: { xs: '15px', sm: '20px', md: '20px' } }}>End Time</Typography></td>
                        <td><Typography sx={{ fontSize: { xs: '15px', sm: '20px', md: '20px' } }}>{formattedEndTime}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{ fontWeight: 'bold', fontSize: { xs: '15px', sm: '20px', md: '20px' } }}>Purpose</Typography></td>
                        <td><Typography sx={{ fontSize: { xs: '15px', sm: '20px', md: '20px' } }}>{appointment?.purpose}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{ fontWeight: 'bold', fontSize: { xs: '15px', sm: '20px', md: '20px' } }}>Location</Typography></td>
                        <td><Typography sx={{ fontSize: { xs: '15px', sm: '20px', md: '20px' } }}>{appointment?.location?.name}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography sx={{ fontWeight: 'bold', fontSize: { xs: '15px', sm: '20px', md: '20px' } }}>Building</Typography></td>
                        <td><Typography sx={{ fontSize: { xs: '15px', sm: '20px', md: '20px' } }}>{appointment?.building?.name}</Typography></td>
                    </tr>
                </tbody>
            </table>
        </Box>
    )
}

export default AppointmentTable