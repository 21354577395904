import { Box, CircularProgress } from "@mui/material";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startValidateExternalApprovalCode } from "./Redux/Slice/commonSlice";
import LinkExpired from "./UI/LinkExpired/LinkExpired";
import RequestApproval from "./Component/Appointment/RequestApproval";
import SignInComponent from "./Component/mrCalander/signIn";
import axios from "axios";


function App() {
  const dispatch = useDispatch()
  const approvalCode = useSelector(state => state?.common?.approvalCode)
  const isCodeLoaded = approvalCode?.isLoaded
  const isCodeValid = approvalCode?.isValid
  const error = approvalCode?.error

  const pathname = window.location?.pathname

  const handleContextMenu = (e) => {
    e.preventDefault()
  }

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu)

    return () => {
        document.removeEventListener('contextmenu', handleContextMenu)  //After event handler is executed,remove it to prevent memeory leaks
    }
  }, [])

  //clickJacking prevention - frameBusting code
  useEffect(() => {
    if (window !== window.top) {
        window.top.location = window.location
    }
  }, [])


  useEffect(() => {

    axios.get(`${process.env.REACT_APP_VISITOR_URL}v1/csrf`, { withCredentials: true })
      .then((response) => {
        if (response?.status === 200) {
          localStorage.setItem('csrfToken', response?.data?.csrf_token)

          if (pathname !== '/ms/redirect') {        //code related to vms
            const encryptedCode = pathname?.slice(1)
            if (encryptedCode?.length > 0) {
              const data = { encryptedCode }
              dispatch(startValidateExternalApprovalCode(data))
            } else {
              const encryptedCode = localStorage.getItem('encryptedCode')
              const data = { encryptedCode }
              dispatch(startValidateExternalApprovalCode(data))
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  // Check if the path is '/ms/redirect' and return the RedirectComponent
  if (pathname === '/ms/redirect') {
    return <SignInComponent />;
  }

  return (
    <Box sx={{ height: '90vh', width: { sx: '80vw', sm: '95vw', md: "100vw" }, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
      {
        isCodeLoaded ? (
          <Box>
            {isCodeValid ? (
              <RequestApproval />
            ) : (
              <LinkExpired error={error} />
            )}
          </Box>
        ) : (
          <CircularProgress sx={{ color: "black" }} />
        )
      }
    </Box>
  );
}

export default App;
