import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";

function SignInComponent() {
    const [code, setCode] = useState("");
    const [tenantName, setTenantName] = useState("");

    useEffect(() => {
        const handleRedirect = () => {
            if (window.location.href.includes("?code=")) {
                const code = window.location.href?.split("?code=")[1]?.split("&session_state=")[0];

                if (code.length > 0) {
                    setCode(code);
                }
            }
        };

        // Check for the presence of an authorization code on component mount
        handleRedirect();
    }, []);

    const fetchData = () => {
        // Redirect the user to the Microsoft login page to initiate the OAuth flow
        window.location.replace(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env?.REACT_APP_MS_CLIENT_ID}&response_type=code&redirect_uri=${process.env?.REACT_APP_MS_REDIRECT_URI}&response_mode=query&scope=${process.env?.REACT_APP_MS_SCOPES}`);
    };


    const handleDownload = (blob) => {
        saveAs(blob, 'auth_code.txt');
        window.location.replace('/')
    };

    if (code.length > 0) {
        const jsonString = JSON.stringify(code);
        const blob = new Blob([jsonString], { type: "application/json" });

        handleDownload(blob)
    }

    return (
        <div className="App">
            {code.length === 0 && < div ><input placeholder='enter the tenant name' type="text" value={tenantName} onChange={(e) => setTenantName(e.target.value)
            } />
                <br />
                <br />
                <button disabled={tenantName?.toLowerCase().includes("nha") > 0 ? false : true} onClick={fetchData}>Sign In</button></div>}
        </div >
    );
}

export default SignInComponent;
