import { Box, Typography } from '@mui/material'
import React from 'react'
import Lottie from 'react-lottie'
import expired from '../../Assets/Animation/expired.json'

const LinkExpired = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: expired,
    };
    return (
        <Box>
            <Box>
                <Lottie
                    style={{
                        height: '200px',
                        width: '200px'
                    }}
                    options={defaultOptions}
                />
            </Box>
            <Box textAlign={'center'}>
                <Typography sx={{ fontSize: { xs: '15px', sm: '25px', md: '25px' } }}>
                    {props.error}
                </Typography>
            </Box>
        </Box>
    )
}

export default LinkExpired