import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import AppointmentTable from './AppointmentTable'
import NotesBox from './NotesBox'

const RequestApproval = (props) => {
    const [buttonClicked, setButtonClicked] = useState(false)
    const [status, setStatus] = useState("")

    const company = useSelector(state => state.common?.company)
    const appointment = useSelector(state => state.common?.appointment)

    const handleButtonClick = (status) => {
        setButtonClicked(true)
        setStatus(status)
    }

    return (
        <Box sx={{ height: "90vh", width: { xs: '300px', sm: '450px', md: '600px' }, display: "flex", flexDirection: "column", justifyContent: "space-around", border: { md: "0.5px solid grey" }, borderRadius: { md: "10px" } }} >
            <Box sx={{ height: "10vh", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                <img
                    src={company?.logo}
                    alt="company_logo"
                    style={{ height: '30px' }}
                />
            </Box>
            <Box sx={{ height: '80vh', mt: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <Box >
                    <AppointmentTable />
                </Box>
                {appointment?.status !== 'awaiting' ? (
                    <Box sx={{ mt: 5 }}>
                        <Typography sx={{ fontSize: { xs: '15px', sm: '25px', md: '25px' } }}>This Appointment is {appointment?.status}.</Typography>
                    </Box>
                ) : (
                    <Box sx={{ height: "60%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: "center" }}>
                        <Box sx={{ mt: { xs: 3, sm: 9, md: 8 }, height: { xs: '40%', sm: '30%', md: "30%" }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: "center" }} >
                            <Box>
                                <Typography align={'center'} variant='h6'>Do you accept this request?</Typography>
                                <Typography align={'center'} variant='caption'>Note : You won't be able to change it later!</Typography>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }} >
                                <Button
                                    sx={{
                                        width: 100,
                                        bgcolor: '#F46326',
                                        color: '#fff',
                                        textTransform: 'none',
                                        ":hover": {
                                            bgcolor: '#dc4d28'
                                        },
                                    }}
                                    onClick={() => { handleButtonClick('accepted') }}
                                >Yes</Button>
                                <Button
                                    sx={{
                                        width: 100,
                                        bgcolor: '#F46326',
                                        color: '#fff',
                                        textTransform: 'none',
                                        ":hover": {
                                            bgcolor: '#dc4d28'
                                        },
                                    }}
                                    onClick={() => { handleButtonClick('cancelled') }}
                                >No</Button>
                            </Box>
                        </Box>
                        <Box sx={{ height: "40%", width: { xs: '300px', sm: '800px', md: "800px" }, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                            {buttonClicked && <NotesBox status={status} />}
                        </Box>
                    </Box>
                )
                }

            </Box>
        </Box >
    )
}

export default RequestApproval