import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import configureStore from '../src/Redux/Store/store'
import { Provider } from 'react-redux';


const store = configureStore()
console.log('store added', store.getState())
store.subscribe(() => {
  console.log('store Updated', store.getState())
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
