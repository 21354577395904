import { combineReducers } from "redux"
import { configureStore } from '@reduxjs/toolkit';
import commonSlice from "../Slice/commonSlice";

const storeConfigure = () => {
    const rootReducer = combineReducers({
        common: commonSlice
    })
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    });
    return store
}

export default storeConfigure;